<template>
    <slot v-bind="mediaQueries"></slot>
</template>

<script setup lang="ts">
    import { reactive, defineProps, provide, onMounted, onBeforeUnmount, defineEmits, watch, withDefaults } from 'vue';
    import mediaQueryManager from 'o365.vue.components.MediaQueryManager.ts';
    import { mediaQueryProviderKey } from 'o365.modules.vue.injectionKeys.js';

    export interface IProps {
        queries?: {
            [key: string]: string
        };
        fallback?: Array<string> | string;
    };

    export interface IMediaQueryResults {
        [key: string]: boolean;
    };

    const props = withDefaults(defineProps<IProps>(), { queries: () => ({}) });

    const mediaQueries: IMediaQueryResults = reactive({});

    const emit = defineEmits<{ (e: 'change', queryName: string , queryResult: boolean): void }>();

    provide(mediaQueryProviderKey, mediaQueries);

    // ---- Lifecycle Hooks ---- //
    onMounted(() => {
        mediaQueryManager.on('QueryChanged', onChange);
        run();
    });

    onBeforeUnmount(() => {
        mediaQueryManager.off('QueryChanged', onChange);
    });

    // ---- Watchers ---- //
    watch(() => props.queries, () => run());

    // ---- Methods ---- //
    function run() {
        const results = mediaQueryManager.registerCustomQueries(props.queries);

        for (const queryName in results) {
            let queryResult = results[queryName];

            if (queryResult === undefined) {
                if (Array.isArray(props.fallback)) {
                    queryResult = props.fallback.includes(queryName);
                } else {
                    queryResult = (queryName === props.fallback);
                }
            }
            
            mediaQueries[queryName] = queryResult;
        }
    }

    function onChange(queryName: string, queryResult: boolean) {
        if (!mediaQueries.hasOwnProperty(queryName)) {
            return;
        }

        emit('change', queryName, queryResult);
        mediaQueries[queryName] = queryResult;
    }
</script>